import { useContextState } from "../../Context";
import "./ShopPageOne.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardOne from "./CardOne";

function ShopPageOne({ products, userID }) {
  const { userLoading } = useContextState();
  const { productLoading } = useContextState();
  const { searchQuery } = useContextState();

  const availableProducts = products?.filter(
    (item) => item.productIsLimitedOffer !== true
  );

  const limitedTimeOffers = products?.filter(
    (item) => item.productIsLimitedOffer === true
  );

  let content;
  if (!searchQuery) {
    content =
      (userLoading && productLoading) || !availableProducts ? (
        <h1>Loading...</h1>
      ) : (
        availableProducts.map((item) => (
          <CardOne key={item?.productID} data={item} userID={userID} />
        ))
      );
  } else if (searchQuery) {
    content =
      products.length > 0 ? (
        products.map((item) => <CardOne key={item?.productID} data={item} />)
      ) : (
        <h1>No product found for "{searchQuery}"</h1>
      );
  }

  return (
    <div className="main">
      <ToastContainer />
      <div className="container">
        <h1 className="homepage__title">Our Packages</h1>
        <div className="shopPageOne">{content}</div>
      </div>
      {limitedTimeOffers?.length > 0 && !searchQuery && (
        <div className="limitedOffer__container">
          <div className="limitedOffer__header">
            <h1>Limited Time Offers</h1>
          </div>

          <div className="limitedOffer__items container">
            {limitedTimeOffers.map((item) => (
              <CardOne data={item} key={item.productID} userID={userID} />
            ))}
          </div>
        </div>
      )}

      <div className="bg__lines__wrapper">
        <div className="bg__lines">
          <div className="stripe stripe1">
            <div className="stripe__child"></div>
          </div>
          <div className="stripe stripe2">
            <div className="stripe__child"></div>
          </div>
          <div className="stripe stripe3">
            <div className="stripe__child"></div>
          </div>
          <div className="stripe stripe4">
            <div className="stripe__child"></div>
          </div>

          <div className="stripe stripe__round"></div>
        </div>
      </div>
    </div>
  );
}

export default ShopPageOne;
