import React, { useState } from "react";
import "./FloatingCart.css";
import { useDispatch } from "../../Context";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import RemoveIcon from "@mui/icons-material/Remove";
import { useContextState } from "../../Context";

function FloatingCart() {
  const [cartOpen, setCartOpen] = useState(() => false);
  const [paymentClicked, setPaymentClicked] = useState(() => false);
  const { cart, cartLoading } = useContextState();
  const { sendRequest: sendPayment } = useDispatch();

  const handlePayment = async () => {
    setPaymentClicked(true);
    sendPayment("cart/payment", "POST", {
      ...cart,
      token: localStorage.getItem("token"),
    });
  };

  const getItemCount = (items) =>
    items?.reduce((amount, item) => item.quantity + amount, 0);

  return (
    <div className="floatingCart">
      {!cartOpen ? (
        <div onClick={() => setCartOpen(true)} className="cartClosed">
          <ShoppingBagIcon />
          <h2>
            {!getItemCount(cart.products) ? 0 : getItemCount(cart.products)}
          </h2>
        </div>
      ) : (
        <div className="cartOpened">
          <button onClick={() => setCartOpen(false)}>
            <RemoveIcon />
          </button>
          <div className="totalPrice">
            {" "}
            <ShoppingBagIcon /> Your cart total: €{" "}
            {cart?.totalPrice?.toFixed(2) || (0.0).toFixed(2)}
          </div>
          <h2
            disabled={cartLoading ? true : false}
            className={`checkout-icon ${
              (!cart?.products?.length || cartLoading || paymentClicked) &&
              "disabled-checkout-btn"
            }`}
            onClick={handlePayment}
          >
            Checkout
          </h2>
        </div>
      )}
    </div>
  );
}

export default FloatingCart;
