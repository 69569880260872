import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import Logo2 from "../../assets/logo2.png";

function Footer() {
  return (
    <div className="footer">
      <img className="footer__logo" src={Logo2} alt="" />
      <div className="footer__inner">
        <h3>Copyright 2022 OncoPet, All rights reserved.</h3>
        <h4
          onClick={() =>
            window.location.replace("https://oncopet.org/ws_terms")
          }
        >
          Terms & Conditions
        </h4>
      </div>
    </div>
  );
}

export default Footer;
