import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { useContextState, useDispatch } from "../../Context";
import { useState } from "react";
import { toast } from "react-toastify";

function CardOne({ data, userID }) {
  const { sendRequest } = useDispatch();
  const [count, setCount] = useState(1);
  const {
    productName,
    productShortDescription,
    productImageURL,
    price,
    maxOrderCount,
    productID,
    productSubTitle,
    productIsLimitedOffer,
  } = data;
  const { cart, cartLoading, user } = useContextState();

  const onAdd = () => {
    toast.dismiss();
    if (user?.logout) return;
    // maxOrderCount
    const addingProduct = cart?.products?.find(
      (item) => item.productId === productID
    );
    if (addingProduct?.quantity === maxOrderCount) {
      toast.error(`You've reached the maximum order count!`, {
        autoClose: 2000,
      });
      return;
    }
    if (
      addingProduct?.quantity + count > maxOrderCount ||
      count > maxOrderCount
    ) {
      toast.error(`You can only order ${maxOrderCount} from this product`, {
        autoClose: 2000,
      });
      return;
    }

    /// Adding to cart
    const product = {
      ...data,
      quantity: count,
      productId: productID,
    };

    sendRequest(`cart/${userID}`, "POST", {
      product,
      userInternalID: userID,
    });
    toast("Product added to cart", { autoClose: 2000 });
    setCount(1);
  };

  function AddCount() {
    setCount(count + 1);
  }

  function MinusCount() {
    count > 1 && setCount(count - 1);
  }

  return (
    <div className={`cardOne ${productIsLimitedOffer && "limitedCardOne"}`}>
      <div className="cardOne__top">
        {productIsLimitedOffer && (
          <h2 className="limitedCardOne__hours">{productSubTitle}</h2>
        )}
        <div className="cardOne__hour__wrapper">
          <div className="cardOne__hour">
            <div className="cardOne__hour__border">
              <img src={productImageURL} alt={productName} />
            </div>
          </div>
        </div>
      </div>
      <div className="cardOne__bottom">
        <div className="cardOne__title">
          <h2>{productName}</h2>
          <p>{data.productShortSubTitle}</p>
        </div>
        <p className="cardOne__info">{productShortDescription}</p>
        <div className="cardOne__price">
          <h3>€ {price}</h3>
          <div className="itemCounter">
            <button onClick={MinusCount} className="minus">
              <RemoveCircleIcon />
            </button>
            <p>{count}</p>
            <button onClick={AddCount} className="plus">
              <AddCircleIcon />
            </button>
          </div>
        </div>
        <button
          disabled={cartLoading ? true : false}
          onClick={onAdd}
          className="cardOne__btn"
        >
          <div>
            <LocalMallIcon /> Add to cart
          </div>
          <div className="cartPriceCounter">
            <span>€</span>
            <h3>{count * price}</h3>
          </div>
        </button>
      </div>
    </div>
  );
}

export default CardOne;
