const redirect = (error, user) => {
  const hasQuestionMark = localStorage.getItem("exitURL")?.includes("?");

  // Redirecting the user based on reason
  if ((error && error?.data?.code === "TIMEOUT") || user?.logout) {
    console.log("error", error?.data);
    localStorage.removeItem("token");
    window.location.replace(
      `${localStorage.getItem("exitURL")}${
        hasQuestionMark ? "&" : "?"
      }exitCode=${user?.logout ? "logout" : "timeout"}`
    );
  }
  if (error && error?.data?.code === "INV_TOKEN") {
    console.log("error", error?.data);
    localStorage.removeItem("token");
    window.location.replace(
      `${localStorage.getItem("exitURL")}${
        hasQuestionMark ? "&" : "?"
      }exitCode=invalidToken`
    );
  }
};

export default redirect;
