import React, { useContext, useState } from "react";
import "./Header.css";
import SearchIcon from "@mui/icons-material/Search";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ClearIcon from "@mui/icons-material/Clear";
import LogoImg from "../../assets/logo.png";
import { dispatchContext, useContextState, useDispatch } from "../../Context";

function Header() {
  const OpenSearch = () => {
    const z = document.querySelector(".clearSearch");
    const x = document.querySelector(".search");
    const y = document.querySelector(".search input");
    const a = document.querySelector(".openSearch");
    y.style.display = "block";
    z.style.display = "block";
    a.style.display = "none";
    x.classList.add("searchShow");
  };
  const CloseSearch = () => {
    const a = document.querySelector(".openSearch");
    const z = document.querySelector(".clearSearch");
    const x = document.querySelector(".search");
    const y = document.querySelector(".search input");
    y.style.display = "none";
    z.style.display = "none";
    a.style.display = "block";
    x.classList.remove("searchShow");
    setSearchedQuery("");
    dispatch({ type: "search", payload: "" });
  };
  const [searchedQuery, setSearchedQuery] = useState(() => "");
  const { sendRequest: logoutUser } = useDispatch();

  const dispatch = useContext(dispatchContext);
  const { user } = useContextState();

  const searchHandler = (searchQuery) => {
    setSearchedQuery(searchQuery.toLowerCase());
    dispatch({ type: "search", payload: searchQuery.toLowerCase() });
  };

  const logoutHandler = () => {
    logoutUser(`user/info/${user?.userInternalID}`, "PATCH", { logout: true });
    window.location.replace(
      `${user?.exitURL}${
        localStorage.getItem("exitURL")?.includes("?") ? "&" : "?"
      }exitCode=logout`
    );
    localStorage.removeItem("token");
  };

  return (
    <div className="container">
      <div className="header">
        <div className="logout__link">
          <div className="logout__link--btn" onClick={logoutHandler}>
            {" "}
            <ChevronLeftIcon /> Logout
          </div>
        </div>
        <div
          className="header__logo"
          onClick={() => window.location.replace("https://oncopet.org/ws_home")}
        >
          <img src={LogoImg} alt="" />
        </div>
        <div className="search">
          <input
            type="text"
            name=""
            placeholder="Search..."
            onChange={(e) => searchHandler(e.target.value)}
            value={searchedQuery}
          />
          <span className="clearSearch" onClick={CloseSearch}>
            {" "}
            <ClearIcon />{" "}
          </span>
          <span className="openSearch" onClick={OpenSearch}>
            <SearchIcon />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Header;
