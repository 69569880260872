import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./HomePage.css";
import ShopPageOne from "../ShopPageOne";
import Header from "../Header";
import Footer from "../Footer";
import FloatingCart from "../FloatingCart";
import ShoppingCartOne from "../ShoppingCartOne";
import { useEffect } from "react";
import { useContextState, useDispatch } from "../../Context";
import jwt_decode from "jwt-decode";
import redirect from "../../utils/redirect";

function HomePage() {
  const navigate = useNavigate();
  const params = useLocation();

  const [decoded] = useState(() => {
    let token = params.search.split("=")[1];
    // After purchase, barion calls the redirect url with &paymentId={{id}}. So we need to remove that
    if (token.includes("&paymentId")) token = token.split("&")[0];
    localStorage.setItem("token", token);
    if (token) return jwt_decode(token);
  });

  const { sendRequest: fetchUser } = useDispatch();
  const { sendRequest: fetchProducts } = useDispatch();

  const [availableProducts, setAvailableProducts] = useState([]);

  useEffect(() => {
    fetchUser(`user/info/${decoded.id}`);
    fetchProducts("webshop/products");
  }, [decoded.id, fetchProducts, fetchUser]);

  const { user, error } = useContextState();
  const { products, error: prodError } = useContextState();
  const { searchQuery } = useContextState();

  useEffect(() => {
    if (user) {
      if (!localStorage.getItem("exitURL")) {
        user?.exitURL && localStorage.setItem("exitURL", user?.exitURL);
      }

      if (user.userPriceTable?.length > 0 && products?.length > 0) {
        const updatedUserPriceTable = user.userPriceTable.filter(
          (item) => item.productNetPrice >= 0 && item.productNetPrice !== null
        );
        setAvailableProducts(
          updatedUserPriceTable.map((item) => {
            const product = products?.find(
              (p) => p?.productID === item.productID
            );

            return {
              ...product,
              price: item.productNetPrice,
              productVatRate: item.productVatRate,
            };
          })
        );

        navigate(`/?auth=${localStorage.getItem("token")}`);
      }
    }

    // Redirecting the user based on reason
    redirect(error, user);
  }, [products, user?.userPriceTable, user, error, prodError, navigate]);

  return (
    <>
      <div className="homepage">
        <div className="homepage__inner">
          <Header />
          <ShopPageOne
            products={availableProducts.filter((item) => {
              return (
                item.productName.toLowerCase().includes(searchQuery) ||
                item.productLongDescription
                  .toLowerCase()
                  .includes(searchQuery) ||
                item.productShortDescription.toLowerCase().includes(searchQuery)
              );
            })}
            userID={decoded.id}
          />
          <FloatingCart />
        </div>
        <ShoppingCartOne
          id={decoded.id}
          availableProducts={availableProducts}
        />
        <Footer />
      </div>
    </>
  );
}

export default HomePage;
