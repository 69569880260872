import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ShoppingCartOne.css";
// Icons MUI
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import "react-toastify/dist/ReactToastify.css";
import CartItem from "./CartItem";
import { useContextState, useDispatch } from "../../Context";
import { toast } from "react-toastify";

function ShoppingCartOne({ id, availableProducts }) {
  const navigate = useNavigate();
  const [paymentClicked, setPaymentClicked] = useState(() => false);

  const { sendRequest } = useDispatch();
  const { sendRequest: sendPayment } = useDispatch();
  const { cart, cartLoading, payment } = useContextState();

  const ScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    payment?.barionUrl && window.location.replace(payment.barionUrl);
    // this is when the user only buys products with zero prices.
    if (payment.newPurchase && !payment.barionUrl) {
      setPaymentClicked(false);
      toast("Purchase was successfull", { autoClose: 2000 });
    }
  }, [navigate, payment, payment.barionUrl]);

  useEffect(() => {
    sendRequest(`cart/${id}`);
  }, [sendRequest, id]);

  const handlePayment = async () => {
    setPaymentClicked(true);
    sendPayment("cart/payment", "POST", {
      ...cart,
      token: localStorage.getItem("token"),
    });
  };

  return (
    <div className="shoppingCartOne__wrapper">
      <div className="shoppingCartOne container">
        <h1 className="shoppingCartOne__title">Your shopping cart</h1>
        <div className="shoppingCartOne__header">
          <div className="shoppingCartOne__header--left">
            <h4>Product Picture</h4>
            <h4>Item name & Description</h4>
          </div>
          <h4>Quantity</h4>
          <h4>Price</h4>
        </div>
        <div className="shoppingCartOne__items">
          {cart?.products?.length ? (
            cart.products.map((item) => (
              <CartItem
                item={item}
                key={item.productId}
                userID={id}
                availableProducts={availableProducts}
              />
            ))
          ) : (
            <h2 style={{ textAlign: "center", marginTop: "20px" }}>
              Empty Cart
            </h2>
          )}
        </div>

        <div className="shoppingCartOne__bottom">
          <div className="continueShopping">
            <button onClick={ScrollTop} className="shop__again__btn">
              <span>
                <ExpandCircleDownIcon />
              </span>
              Continue Shopping
            </button>

            <button
              disabled={cartLoading ? true : false}
              className={`pay__btn ${
                (!cart?.products?.length || paymentClicked) && "disabled-btn"
              }`}
              onClick={handlePayment}
            >
              <span>
                <CreditCardIcon />
              </span>
              Pay € {cart?.totalPrice?.toFixed(2) || (0.0).toFixed(2)}
            </button>
          </div>

          <div className="subtotalCard">
            <div>
              <h4>Total Net Price:</h4>
              <h4>€ {cart?.subTotalPrice?.toFixed(2) || (0.0).toFixed(2)}</h4>
            </div>
            <div>
              <h4>VAT:</h4>
              <h4>€ {cart?.totalVat?.toFixed(2) || (0.0).toFixed(2)}</h4>
            </div>
            <div>
              <h4 style={{ marginRight: "5px" }}>Total Gross Price:</h4>
              <h4> € {cart?.totalPrice?.toFixed(2) || (0.0).toFixed(2)}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="bg__line2__wrapper">
        <div className="bg__lines">
          <div className="stripe stripe5">
            <div className="stripe__child"></div>
          </div>
          <div className="stripe stripe6">
            <div className="stripe__child"></div>
          </div>
          <div className="stripe stripe7">
            <div className="stripe__child"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShoppingCartOne;
