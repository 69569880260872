import axios from "axios";
import { createContext, useCallback, useContext, useReducer } from "react";

const API_URL = "https://api.dev.webshop.oncopet.org";
// const API_URL = "http://127.0.0.1:8000";

const stateContext = createContext();
export const dispatchContext = createContext();

export const getBasketTotal = (items) =>
  items?.reduce(
    (amount, item) => item.purchasedNetUnitPrice * item.purchasedQty + amount,
    0
  );

const reducer = (state, { type, payload }) => {
  if (type === "req") {
    return { ...state, cartLoading: true, productLoading: true };
  }

  if (type === "res") {
    if (payload.userFullName) {
      return { ...state, user: payload, userLoading: false };
    }

    if (Array.isArray(payload)) {
      return { ...state, products: payload, productLoading: false };
    }

    if (payload.cart) {
      return { ...state, cart: payload.cart, cartLoading: false };
    }

    if (payload.data?.newPurchase) {
      // this is when the user only buys products with zero price. The cart should be immediately emptied after successful payment without barion
      if (!payload.data.barionUrl) {
        return {
          ...state,
          payment: payload.data,
          paymentLoading: false,
          cartLoading: false,
          cart: {},
        };
      }
      return { ...state, payment: payload.data, paymentLoading: false };
    }

    if (payload.customPurchaseInfo) {
      return { ...state, purchase: payload.customPurchaseInfo };
    }
  }

  if (type === "search") return { ...state, searchQuery: payload };

  if (type === "err") return { ...state, error: payload };
};

const initialState = {
  user: {},
  userLoading: true,
  products: [],
  productLoading: true,
  cart: {},
  searchQuery: "",
  payment: {},
  paymentLoading: false,
  purchase: {},
  cartLoading: true,
  error: null,
};

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <stateContext.Provider value={state}>
      <dispatchContext.Provider value={dispatch}>
        {children}
      </dispatchContext.Provider>
    </stateContext.Provider>
  );
};

const useContextState = () => {
  const contextState = useContext(stateContext);
  if (contextState === undefined)
    throw new Error("contextState must be used within a contextProvider");
  return contextState;
};

const useDispatch = () => {
  const dispatch = useContext(dispatchContext);

  if (dispatch === undefined)
    throw new Error("useDispatch must be used within a contextProvider");
  const sendRequest = useCallback(
    async (url, method, reqBody = null) => {
      const fullURL = `${API_URL}/api/v1/${url}`;
      dispatch({ type: "req" });

      try {
        const response = await axios({
          headers:
            method === "DELETE"
              ? {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
              : {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
          method,
          url: fullURL,
          data: reqBody,
        });

        if (response) {
          dispatch({ type: "res", payload: response.data });
        }
      } catch (error) {
        dispatch({ type: "err", payload: error.response });
      }
    },
    [dispatch]
  );

  return {
    dispatch,
    sendRequest,
  };
};

export { ContextProvider, useContextState, useDispatch };
