import React from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
// import { CartDispatchContext, clearCart, removeFromCart, updateQty } from "../../cart"
import { toast } from "react-toastify";
import { useContextState, useDispatch } from "../../Context";

const CartItem = ({ item, userID, availableProducts }) => {
  const { sendRequest } = useDispatch();
  const { cartLoading, products, user } = useContextState();

  const onAdd = (quantity) => {
    toast.dismiss();
    if (user?.logout) return;
    // maxOrderCount
    const addingProduct = products?.find(
      (product) => product.productID === item.productId
    );
    if (quantity > 0 && item.quantity === addingProduct.maxOrderCount) {
      toast.error(`You've reached the maximum order count!`, {
        autoClose: 2000,
      });
      return;
    }
    // Finding vatRate of adding product
    const addingProductVatRate = availableProducts.find(
      (product) => product.productID === item.productId
    ).productVatRate;

    const product = {
      ...item,
      quantity,
      productId: item.productId,
      productVatRate: addingProductVatRate,
    };
    sendRequest(`cart/${userID}`, "post", {
      product,
      userInternalID: userID,
    });
    toast(
      `${
        item.quantity === 1
          ? "Product removed from cart"
          : "Product order count updated!"
      }`,
      {
        autoClose: 2000,
      }
    );
  };

  return (
    <div key={item._id} className="sco__itemCard">
      <div className="sco__itemInfo">
        <div className="sco__image">
          <img src={item.productImageURL} alt="" />
        </div>
        <div className="sco__info">
          <h1 className="sco__title">{item.productName}</h1>
          <p className="sco__description">{item.productShortDescription}</p>
        </div>
      </div>
      <div className="itemCounter">
        <button
          disabled={cartLoading ? true : false}
          onClick={() => onAdd(-1)}
          className="minus"
        >
          <span>
            <RemoveCircleIcon />
          </span>
        </button>
        <p>{item.quantity}</p>
        <button
          disabled={cartLoading ? true : false}
          onClick={() => onAdd(1)}
          className="plus"
        >
          <span>
            <AddCircleIcon />
          </span>
        </button>
      </div>
      <div className="sco__price">
        <h2>€ {item.quantity * item.price}</h2>
      </div>
      <button
        className="sco__removeBtn"
        disabled={cartLoading ? true : false}
        onClick={() => onAdd(-item.quantity)}
      >
        <CancelIcon />
      </button>
    </div>
  );
};

export default CartItem;
